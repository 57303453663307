@tailwind base;
@tailwind components;
@tailwind utilities;

/* src/index.css */

.login-container, .dashboard-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
}

.error {
  color: red;
  text-align: center;
}

form div {
  margin-bottom: 15px;
}

form label {
  display: block;
  margin-bottom: 5px;
}

form input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}


/* styles.css */
@media print {
  @page {
    size: A4;
    margin: 0; /* Adjust margins as needed */
  }

  .a4 {
    width: 210mm;  /* A4 width */
    height: 297mm; /* A4 height */
  }
}


@media print {
  .a4-container {
    width: 210mm;
    height: 297mm;
    margin: auto;
    padding: 0;
    box-sizing: border-box;
  }

  .a4 {
    width: 210mm;
    height: 297mm;
    margin: auto;
    box-sizing: border-box;
  }
}
